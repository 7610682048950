<template>
  <main class="container">
    <div class="header h3 mt-2">
          Sub-estaciones
        <!-- <button class="btn-2 float-end" @click="showNewModal=true"><i class="fas fa-plus me-2"/> Agregar Nuevo</button> -->
    </div>

    <!-- ADD NEW MODAL -->
    <b-modal id="modal-1" title="Agregar Región" class="p-3" centered v-model="showNewModal" hide-footer>
        <form @submit.prevent="postData()" class="mx-3">
            <label class="text-green-2">Código:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="newItem.id">
            
            <label class="text-green-2">Nombre:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="newItem.name">
            
            <label class="text-green-2">Dirección:</label>
            <input type="text" class="form-control border-secondary mb-4" required v-model="newItem.address">

            <button class="btn-1 float-end" type="submit">Guardar <i class="fas fa-save ms-2"></i></button>
            <button class="btn-3 float-end me-2" type="button" @click="showNewModal=false">Cencelar <i class="fas fa-times ms-2"></i></button>
        </form>
    </b-modal>

    <!-- EDIT MODAL -->
    <b-modal id="modal-1" title="Agregar Región" class="p-3" centered v-model="showEditModal" hide-footer>
        <form @submit.prevent="putData()" class="mx-3">
            <label class="text-green-2">Código:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="selectedItem.id" readonly>
            
            <label class="text-green-2">Nombre:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="selectedItem.name">
            
            <label class="text-green-2">Dirección:</label>
            <input type="text" class="form-control border-secondary mb-4" required v-model="selectedItem.address">

            <button class="btn-1 float-end" type="submit">Guardar <i class="fas fa-save ms-2"></i></button>
            <button class="btn-3 float-end me-2" type="button" @click="showEditModal=false">Cencelar <i class="fas fa-times ms-2"></i></button>
        </form>
    </b-modal>

    <!-- DETAILS -->
    <div class="row mt-2 mx-1 " 
         style="min-height:75vh; background:rgba(0,0,0,0.5);">
        
        <div class="col">            
            <b-table
            id="my-table"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields">
            
                 <template #cell(acciones)="data">
                    <div>
                        <button @click="$router.push('/station/'+data.item.id+'/assignWarehouses')" title="Almacenes" class="btn-1" style="min-width:40px; "><i class="fas fa-list"></i></button>
                        <button @click="showEditModal=true; selectedItem=data.item" class="btn-1 ms-2" style="min-width:40px;;"><i class="fas fa-pen"></i></button>
                        <!-- <button @click="deleteItem(data.item)" class="btn-1 ms-2" style="min-width:40px;;"><i class="fas fa-trash"></i></button> -->
                    </div>
                </template>

            </b-table>

            <b-pagination class="float-end" v-if="items.length > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>
        </div>
    </div>
  </main>
</template>

<script>
export default {
props:["id"],
computed: {
    rows() {
    return this.items.length
    }
},
data(){
    return {
        items:[
            // {id:"048", name: "Fraijanes", address: "1ra Avenida 3-95 Fraijanes"},
        ],
        fields: [
          { key: "id", label:"Código"},
          { key: "name", label:"Nombre"},
          { key: "address", label:"Dirección"},
          { key: "acciones", tdClass:"text-end", thClass:"text-end pe-5"},          
        ],
        perPage: 10,
        currentPage: 1,

        newItem:{},
        showNewModal:false,
        selectedItem:{},
        showEditModal:false,
        table: 'subStations',
    }
},
async mounted(){
    await this.getData();
},
methods:{
    async getData(){
        try {
            let res = await this.$store.dispatch('get', {path: this.table + '/getAll'});
            //console.log(res)
            if (res.length >0){
                this.items = res
            }
        } catch (error) {
            this.items = []
            console.error('error', error)
        }
    },    
    async putData(){
        try {
            //console.log(this.selectedItem)
            let data = Object.assign({} , this.selectedItem) 
            delete(data.id)
            let res = await this.$store.dispatch('put', {path: this.table + '/modify/3' , data: data});
            if (res.error){
                this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
            }
            else{
                this.showEditModal=false
                this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
            }
        } catch (error) {
            this.items = []
            console.error('error--->', error)
        }
    },   
    async deleteItem(item){
        const {isConfirmed} = await this.$swal({
            title:"Confirmar", text:"¿Desea realmente eliminar el registro?", 
            icon:"question",
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            reverseButtons: true

        })
        
    }
}
}
</script>

<style>

</style>